<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">

      <div class="col-xl col-lg col-md">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row mb-0">
                <div class="col-12 mb-2">
                  <span class="cursor-pointer text-muted" @click="sortBy='', $router.push({name:'poison-products'})">{{title}}</span> / 
                  <span class="cursor-pointer text-capitalize text-primary" v-if="currentRouteName">{{ currentRouteName.replaceAll('-',' ') }}</span>
                </div>
                <div class="col-md-12 mb-4">
                  <div class="d-flex mb-0 position-relative">
                    <div class="app-search2 position-relative w-100">
                      <input type="text" placeholder="Search Poison" class="form-control" v-model.trim="searchKeyword" autocomplete="no" required="" @change="handlePageChange(1)" @keyup.enter="handlePageChange(1)"><span class="mdi mdi-magnify search-icony"></span>
                    </div>
                    <div class="ms-2"><button class="btn btn-primary" @click="handlePageChange(1)">Search</button></div>
                    <div class="ms-2"><button class="btn btn-light " @click="clear();handlePageChange(1)">Clear</button></div>
                  </div>
                </div>
                <div class="col-12">
                  <p class="mb-0" v-if="selectedTag">Tag By: <a href="javascript:void(0)" class="btn btn-sm btn-primary ms-1 font-size-13" @click="removeTag()"> {{selectedTag}} <i class="bi bi-x-circle ms-2"></i></a></p>
                </div>
              </div>
             
              <div class="row" v-if="!pageLoading && searchKeyword && !returnData.length">
                <div class="col-12 text-muted text-center mt-3" >
                  <div class="bg-light py-5 rounded-3">
                      <h5><i class="bi bi-shop-window text-muted display-6"></i></h5>
                      <div class="font-size-15 text-muted">No Products Found</div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="pageLoading">
                <div class="col-12 text-muted text-center py-5 mt-0" >
                  <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                </div>
              </div>
              <div class="row" v-else>
                <div
                  class="col-12"
                  v-for="(value, index) in returnData"
                  :key="'pd_'+ index"
                >
                  <div class="table-responsive">
                    <div class="product-box my-0 mb-2 rounded-3 text-nowrap overflow-auto">
                      <div class="d-flex p-2 align-items-center">
                        <div class="position-relative">
                          <div class="product-ribbon badge bg-danger lh-1" v-if="value.status=='out_of_stock'">
                          Out Of Stock
                        </div>
                        <!-- <div class="product-wishlist">
                          <a href="#">
                            <i class="bi bi-heart"></i>
                          </a>
                        </div> -->
                        <img
                          @click="showPdDetail(value)"
                          v-if="value.images.length>0 && !value.is_poison"
                          :src="value.images[0].image"
                          alt height="65"
                          class="d-block rounded-3"
                        />
                        <img
                          @click="showPdDetail(value)"
                          v-else
                          src="@/assets/images/poison.png"
                          alt height="65"
                          class="d-block rounded-3"
                        />
                        </div>
                        <div class="position-relative ms-2 me-3">
                          <p class="text-muted font-size-12 mb-0"> {{value.sub_title}} </p>
                          <h5 class="mb-2 text-dark font-size-15 textwith2lineV2 text-wrap" style="min-width: 210px;" @click="showPdDetail(value)">
                          {{ value.title }}
                          </h5>
                          <div class="text-primary text-start font-size-13 fw-medium" v-if="value.stock>0">
                            <span>{{value.stock }}</span> Left in stock
                          </div>
                          <div class="font-size-13 text-start" v-else>
                              <span class="text-danger">Out Of Stock</span>
                          </div>
                        </div>
                        <div class="position-relative ms-auto text-center text-muted">
                          <div class="font-size-13 d-flex align-items-end text-end">
                            <span class="ms-auto" style="margin-bottom: 2px;">
                              Retail Price
                            </span>
                            <!-- <del v-if="value.price">
                              {{currency}} {{convertCurrencyFormat(value.price,true)}}
                            </del> -->
                          </div>
                          <div class="font-size-14 mb-0 text-end">
                          <span class="ms-auto text-body font-size-12" v-if="value.merchant_price">
                            {{currency}} <strong class="fw-medium font-size-18 lh-1 text-dark" v-if="value.price">{{convertCurrencyFormat(value.merchant_price,true)}}</strong>
                          </span>
                        </div>
                        </div>
                        <div class="position-relative ms-3">
                          <div class="d-flex align-items-center" style="min-width: 150px;">
                          <div class="input-group mb-0">
                              <button class="btn btn-primary text-white p-1 py-0" @click="decrementModal(value,index)" :disabled="!value.stock>0 "><i class="bi bi-dash font-size-16 lh-0"></i></button>
                              <input type="text" style="max-width: 100px; height: 38px;" v-model="value.qty" class="form-control form-control-md text-center shadow-none" :max="value.variant_selected.stock" :stock="value.stock" @blur="quantitySingleV2(value,index)" :disabled="!value.stock>0" @input="inputNumberOnlyV3($event,'returnData','qty', index)">
                              <button class="btn btn-primary p-1 py-0" @click="incrementModal(value,index)" :disabled="!value.stock>0 || value.qty==value.stock"><i class="bi bi-plus font-size-16 lh-0"></i></button>
                            </div>
                          </div>
                        </div>
                        <div class="position-relative ms-3 d-flex align-items-center mb-0 text-center text-nowrap">
                          <button @click="addToCart(value,index)" type="button" :disabled="value.qty==0 || value.stock==0 || value.variant_selected.stock==0 || addToCartLoading[index] || disabled" class="btn btn-primary flex-fill w-100 me-lg-1 font-size-14 mb-0 mb-md-0  me-1 me-md-1 button-width">
                            <i class="bi bi-cart-plus me-md-1"></i>
                            <span class="d-md-inline d-none">Add to Cart </span> 
                            <span class="spinner-border spinner-border-sm ms-1" v-if="addToCartLoading[index]" role="status"
                            aria-hidden="true"></span>
                          </button>
                          <button @click="removeFavourite(value,index)" v-if="value.is_favorited" :disabled="addToFavouriteLoading[index] || disabled" type="button" class="btn btn-light flex-fill w-100 font-size-14 mb-0 mb-lg-0 text-primary fw-medium  me-3 me-md-1 button-width">
                            <i class="bi bi-heart-fill text-primary me-md-1"></i>   
                            <span class="d-md-inline d-none">Favourited</span> 
                            <span class="spinner-border spinner-border-sm ms-1" v-if="addToFavouriteLoading[index]" role="status"
                            aria-hidden="true"></span>
                          </button>
                          <button @click="addToFavourite(value,index)" v-else :disabled="addToFavouriteLoading[index] || disabled" type="button" class="btn btn-light flex-fill w-100 font-size-14 mb-0 mb-md-0 text-secondary me-3 me-md-1 button-width">
                            <i class="bi bi-heart me-md-1"></i> 
                            <span class="d-md-inline d-none">Favourite</span> 
                            <span class="spinner-border spinner-border-sm ms-1" v-if="addToFavouriteLoading[index]" role="status"
                            aria-hidden="true"></span>
                          </button>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
              <!-- end row -->
              <div class="row" v-if="returnData.length">
                <div class="col mt-3">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        hide-ellipsis
                        @change="handlePageChange"
                        v-model="currentPage"
                        :per-page="perPage"
                        :total-rows="totalData"
                      >
                      </b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
<!-- 
              <pre class="font-size-11 h-auto mb-0">
                <div v-for="(value, index) in returnData" :key="index">{{ value.title }} -  {{ value.qty }}/{{ value.stock }}</div>
              </pre> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="'/animate/loading_purple.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
    <productCartnFav ref="modalFunc2" :data="{accessUsername:accessUsername, assessToken:accessToken, updateData:updateData}" @callParentFunction="fromChildFunction"  @callParentFunction2="fromChildFunction2" @callParentFunction3="fromChildFunction3"/>
  </Layout>
</template>

<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
//import DatePicker from "vue2-datepicker";
import productCartnFav from "@/views/pages/app/modal/productCartnFav";
/**
 * Page
 */

const PageTitle = "Poisons Products"
const testToken = "17429567196cf23d4c26c61c49cbb1bac38b350b15"
const testUser = "weijey@outlook.com"
export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common,
   //DatePicker,
    productCartnFav
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      currency: appConfig.currency,
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "YOUR_API_ID",
        apiHash:"YOUR_API_HASH",
      },
      submitted:false,
      loading:false,
      pageLoading:false,
      buttonLoading:[false,false,false],
      currentPage: 1,
      seletedMethod: "",
      sortBy:"",
      typeOfLetter:"",
      searchKeyword: "",
      searchMerchantItemId:"",
      filterType:"created_date",
      searchAmount:"",
      perPage: 20,
      totalData: 0,
      returnData: [],
      returnPaidTotal: 0,
      returnPayoutProcessed:0,
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      disabled:false,
      shortcuts:[],
      create_modify_permission:false,
      updateData:{},
      categoryList:[],
      selectedCategory:"",
      selectedTag:"",
      addToFavouriteLoading:[],
      addToCartLoading:[],
      sortByType:[
        {
          label: "Best Sellers",
          value: "best_sellers",
        },
        {
          label: "Price: Low to High",
          value: "price_low_to_high",
        },
        {
          label: "Price: High to Low",
          value: "price_high_to_low",
        },
        {
          label: "Newest",
          value: "newest",
        },
        {
          label: "Top Rated",
          value: "top_rated",
        }
      ]
    };
  },
  middleware: "authentication",
  async mounted(){
    this.reload();
    this.title = PageTitle
    this.items[1].text = PageTitle
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    this.date_range = this.$refs.commonFunc.last30Days()
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut()
    // this.getCategory();
    // this.sortByQuery();
    this.searchKeyword = this.$route.query.keyword || ''
    this.$router.replace({ query: { keyword: this.searchKeyword } }).catch(() => {});
    await this.handlePageChange(1);
    
  }, 
  created(){
    
  },
  computed: {
    currentRouteId() {
      return this.$route.params.id; // Use params if your route uses params
    },
    currentRouteName() {
      return this.$route.params.name; // Use params if your route uses params
    }
  },
  methods:{
    removeTag() {
      this.selectedTag = ''
      this.$router.push({
        name: 'nova-market'
      }).catch(() => {});
      this.selectedCategory = ''
      this.handlePageChange(1);
    },
    // sortByQuery() {
    //   this.$router.replace({ query: { sortBy: this.sortBy } }).catch(() => {});
    //   this.handlePageChange(1);
    // },
    fromChildFunction:function(e){
      var data = {}
      data = {
        id: e.id,
        category_name: e.title,
      }
      this.navigateToCategory(data);
    },
    fromChildFunction2:function(e){
      this.sortBy =''
      this.$router.replace({
        name: 'nova-market',
        query:{
          tag: e.title
        }
      }).catch(() => {});
      this.selectedTag = e.title
      this.handlePageChange(1);
    },
    fromChildFunction3(){
      this.$refs.main.callVerticalandTriggerTopbar();
    },
    navigateToCategory(value) {
      this.sortBy =''
      this.selectedTag =''
      this.$router.replace({
        name: 'nova-market with categories',
        params: {
          id: value.id,
          name: value.category_name.replace(' ', '-').toLowerCase()
        }
      }).catch(() => {});
      this.selectedCategory = value.id
      this.handlePageChange(1);
    },
    isCategoryActive(categoryId) {
      return categoryId == this.currentRouteId
    },
    showPdDetail(value){
      this.updateData=value;
      setTimeout(() =>  this.$refs.modalFunc2.showProductDetail(),100)
    },
    getCategory(){
      this.$Progress.start();
      this.loading = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername",  this.accessUsername);
      axios({
          method: "post",
          url: appConfig.WebsiteHost + 'controller/category/initCategoryList',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData)
            this.categoryList = [];
            this.categoryList = resData.category_list;
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.loading = false;
          this.$Progress.finish();
      })
      .catch((error)=> {
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
    });
    },
    convertCurrencyFormat(value,show00) {
      if (show00==true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value)
      }else{
          // Assuming this.transactionLimit is defined in your component's data
          return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
      }
    },
    notBeforeToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    DateChanged() {
      console.log("datechange" + this.date_range);
      this.handlePageChange(1);
      this.currentPage = 1;
      this.disable_date = true;
    },
    handlePageChange(current_page) {
      console.log(this.$route.query.keyword)
      this.$router.replace({ query: { sortBy: this.sortBy, keyword: this.searchKeyword } }).catch(() => {});
      this.pageNum = current_page;
      this.currentPage = current_page;
      this.getData(this.pageNum, this.searchKeyword);
      //this.exportData(1)
    },
    edit(value){
      this.updateData=value;
      setTimeout(() =>  this.$refs.modalFunc2.showEditModal("letterSupport"),100)
    },
    remove(value) {
      Swal.fire({
        icon: 'info',
        title: `Confirm Deletion?`,
        html: `<p> Are you sure you want to delete this ${value.subject} from ${value.applicant_info.company_name}?</p> <p class="mb-0"> This action is irreversible, so make sure you want to proceed.</p>`,
        confirmButtonColor: '#FA6541',
        iconColor: '#202360',
        showCancelButton: true,
        confirmButtonText: "Yes"
      }).then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.loading = true
          this.disabled = true
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("letterDbCode", value.letter_db_code);
          axios({
            method: "post",
            url: appConfig.APIHost + "controller/letterOfSupport/deleteLetterOfSupport",
            data: bodyFormData,
            headers: {
              "Content-Type": "multipart/form-data"
            },
          }).then((response) => {
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              this.returnData = []
              Swal.fire({
                icon: 'success',
                title: `Deleted Successfully`,
                html: `The ${value.subject} from ${value.applicant_info.company_name} has been successfully deleted.`,
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              })
              this.handlePageChange(1)
            } else if (resData.status == 440) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              this.$refs.commonFunc.clearData()
              this.$router.push({
                path: "/login",
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              this.loading = false
            }
            this.disabled = false
            this.$Progress.finish();
          }).catch((error) => {
            this.loading = false
            this.disabled = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
          });
        }
      })
    },
    incrementModal(item,index) {
      console.log('Item:', item);
      let max = 0;
      if (item.variants && item.variants.length > 0 && item.variant_selected) {
        max = item.variant_selected.stock;
        if (item.qty < max) {
          this.returnData[index].qty++
          console.log('Incremented qty:', item.qty);
        } else {
          console.log('Reached max variant stock:', max);
        }
      } else {
        max = item.stock;
        if (item.qty < max) {
          this.returnData[index].qty++
          console.log('Incremented qty:', item.qty);
        } else {
          console.log('Reached max stock:', max);
        }
      }
      console.log('final' , item.qty)
    },
    decrementModal(item, index) {
      console.log(item)
        if (item.qty=== 0) {
          this.returnData[index].qty = 1
        }
        if (item.qty== '') {
          this.returnData[index].qty = 1
        }
        if (item.qty== null) {
          this.returnData[index].qty = 1
        }
        if (item.qty=== 1) {
          console.log(item.qty=== 1)
        } else {
          this.returnData[index].qty--
        }
    },
    quantitySingleV2(item,index) {
      var value = item.qty;
      var variant_stock = item.variant_selected.stock || null;
      var stock = item.stock;
      var max = 0
      if (variant_stock !== null) {
          max = parseInt(variant_stock)
      } else {
          max = parseInt(stock)
      }
      console.log(`max: ${max}`)
      //value = value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');
      if (value > max) {
        this.returnData[index].qty = 1
      }

      console.log(value)
      if (value.length == 0) {
        this.returnData[index].qty = 1
      }
      if (value == '') {
        this.returnData[index].qty = 1
      }
      if (value < 1) {
        this.returnData[index].qty = 1
      }
    },
    inputNumberOnly(event, parentModel, childModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this[parentModel][childModel] = numericValue
    },
    inputNumberOnlyV2(event, parentModel, childModel) {
      var numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this[parentModel][childModel] = numericValue
      if(numericValue.length > 2){
        var val = numericValue.replace(/[^0-9]/g, '');
        console.log(val);
        val = val.substr(0, val.length-2)+"."+val.substr(-2);
        this[parentModel][childModel] = val;
      }
    },
    inputNumberOnlyV3(event, parentModel, childModel, index) {
      var numericValue = event.target.value.replace(/[^0-9.]/g, '');
      if (numericValue.length > 0) {
        console.log('sss' + numericValue)
        this[parentModel][index][childModel] = parseInt(numericValue)
      }
    },
    addToCart(value, index){
      this.$Progress.start();
      this.disabled = true;
      this.addToCartLoading[index] = true;
      var bodyFormData = new FormData();
      // bodyFormData.append("accessToken", this.accessToken);
      // bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("accessToken", testToken);
      bodyFormData.append("email", testUser);
      bodyFormData.append("productID", value.id);
      bodyFormData.append("qty", value.qty);
      bodyFormData.append("variantID", value.variant_selected.id || '');
      var variantName = ""
      if (value.variant_selected.id !== undefined) {
          //if got variant
          variantName = `(${value.variant_selected.label})`
      } else {
          variantName = ''
      }

      axios({
          method: "post",
          //url: appConfig.APIHost + 'controller/user/updateUserPassword',
          //url: appConfig.WebsiteHost + 'controller/product/addToCart',
          url: 'https://seveninblank.com/projects/api/test',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          
          var resData = response.data;
          if (resData.status == 200) {
            Swal.fire({
                title: 'Success',
                text: `${value.title} ${variantName} has been added to your shopping cart`,
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'View Cart'
            }).then((result) => {
                //totalCartNumber = totalCartNumber + (1 * item.qty)
                var updateData = {
                  "cart": resData.data.total_cart || 99,
                };
                this.$refs.commonFunc.updateData(JSON.stringify(updateData));
                //this.$emit('callParentFunction3');
                this.fromChildFunction3();
                value.qty = 1
                if (result.isConfirmed) {
                    console.log('go to cart');
                    this.$router.push({name: 'cart'}).catch(() => { });
                }
                this.$refs['modal-product-detail'].hide()

            })
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.disabled = false;
          this.addToCartLoading[index] = false;
          this.$Progress.finish();
        })
        .catch((error)=> {
          this.disabled = false;
          this.addToCartLoading[index] = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
      });
    },
    addToFavourite(value, index){
      this.$Progress.start();
      this.disabled = true;
      this.addToFavouriteLoading[index]=true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      // bodyFormData.append("accessToken", testToken);
      // bodyFormData.append("email", testUser);
      bodyFormData.append("productId", value.id);
      axios({
          method: "post",
          //url: appConfig.APIHost + 'controller/user/updateUserPassword',
          //url: appConfig.WebsiteHost + 'controller/account/addFavoriteProduct',
          url: 'https://seveninblank.com/projects/api/test',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          
          var resData = response.data;
          if (resData.status == 200) {
            var updateData = {
              "favourite": resData.data.total_favourited || 99,
            };
            this.$refs.commonFunc.updateData(JSON.stringify(updateData));
            //this.$emit('callParentFunction3');
            this.fromChildFunction3();
            value.is_favorited = true;
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            //   localStorage.clear();
            //   this.$router.push({
            //       path: "/login",
            // });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.disabled = false;
          this.addToFavouriteLoading[index]=false;
          this.$Progress.finish();
        })
        .catch((error)=> {
          this.disabled = false;
          this.addToFavouriteLoading[index]=false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
      });
    },
    removeFavourite(value,index){
      this.$Progress.start();
      this.disabled = true;
      this.addToFavouriteLoading[index]=true;
      var bodyFormData = new FormData();
      // bodyFormData.append("accessToken", this.accessToken);
      // bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("accessToken", testToken);
      bodyFormData.append("email", testUser);
      bodyFormData.append("productId", value.id);
      axios({
          method: "post",
          //url: appConfig.APIHost + 'controller/user/updateUserPassword',
          url: appConfig.WebsiteHost + 'controller/account/removeFavouriteProduct',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          
          var resData = response.data;
          if (resData.status == 200) {
            var updateData = {
              "favourite": resData.data.total_favourited,
            };
            this.$refs.commonFunc.updateData(JSON.stringify(updateData));
            //this.$emit('callParentFunction3');
            this.fromChildFunction3();
            value.is_favorited = false;
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.disabled = false;
          this.addToFavouriteLoading[index]=false;
          this.$Progress.finish();
        })
        .catch((error)=> {
          this.disabled = false;
          this.addToFavouriteLoading[index]=false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
      });
    },
    search() {
      this.handlePageChange(1);
      this.currentPage = 1;
    },
    clear() {
      this.sortBy= ""
      this.typeOfLetter=""
      this.searchKeyword= ""
      this.selectedCategory = ""
      this.selectedTag = ""
      this.filterType="created_date"
      this.date_range = this.$refs.commonFunc.last30Days()
      // this.$router.replace({
      //   name: 'nova-market'
      // }).catch(() => {});
       this.handlePageChange(1);
      this.currentPage = 1;
    },

    exportToExcel(Data,name,type) {
      var title = name.replace(/ /g, "_");
      var ws = window.XLSX.utils.json_to_sheet(Data);
      let objectMaxLength = []; 
          for (let i = 0; i < Data.length; i++) {
          let value = Object.values(Data[i]);
          for (let j = 0; j < value.length; j++) {
              if (typeof value[j] == "number") {
              objectMaxLength[j] = 5;
              } else {
              objectMaxLength[j] =
                  objectMaxLength[j] >= value[j].length
                  ? parseInt(objectMaxLength[j])
                  : parseInt(value[j].length) + 5;
              }
          }
      }
      var wscols =[]
      objectMaxLength.forEach((value, index) => {
        console.log(value)
        wscols.push({width: objectMaxLength[index]})
      }),
      ws['!cols'] = wscols;
      
      var wb = window.XLSX.utils.book_new();
      window.XLSX.utils.book_append_sheet(wb, ws, name)
      window.XLSX.writeFile(wb, title +'.' + (type || 'xlsx'))
    },
    async getData(pages, keyword){
        this.$Progress.start();
        this.disabled = true;
        this.pageLoading = true;
        this.disable_date = true;
        this.returnData = [];
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("page", pages);
        bodyFormData.append("type", this.typeOfLetter);
        bodyFormData.append("limit", this.perPage);
        bodyFormData.append("keyword", keyword);
        bodyFormData.append("sortBy", this.sortBy);
        bodyFormData.append("filterBy", this.filterType);
        bodyFormData.append("dateStart", this.date_range[0]);
        bodyFormData.append("dateEnd", this.date_range[1]);
          
        if ('tag' in this.$route.query) {
          if (this.$route.query.tag){
            this.selectedTag = this.$route.query.tag
          }
        }else{
         this.selectedTag=""
        }

        if (this.$route.params.id){
          this.selectedCategory = this.$route.params.id
        }else{
          this.selectedCategory = ""
        }


        axios({
            // method: "post",
            //url: `/api/letter_of_support.json`,
            //url: appConfig.APIHost + 'controller/letterOfSupport/getAllLetterOfSupportList',
            method: "get",
            // url: `${appConfig.WebsiteHost}/controller/product/getAllProductListByPagination?category=${this.selectedCategory}&sort=${this.sortBy}&limit=${this.perPage}&page=${pages}&keyword=${keyword}&tag=${this.selectedTag}`,
            url: `api/poisons.json?keyword=${keyword}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.returnData = [];
            var resData = response.data;
            this.totalData = resData.total_products;
            var responseData = resData.products;
            
            if (resData.status == 200) {
              this.returnData = responseData;

              for (let i = 0; i <= responseData.length; i++) {
                this.addToFavouriteLoading.push(false);
                this.addToCartLoading.push(false)
              }
            }
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.disabled = false;
            this.pageLoading = false;
            this.disable_date = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.disabled = false;
            this.pageLoading = false;
            this.disable_date = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type//data.account_type;
          const permission = data.modules.filter(e => e.name==='poison')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
          } else {
            console.log('Module with name "poison" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>
<style scoped>
.product-box{
  border:2px solid #f1f1f1;
  transition: border 0.1s ease-in;
}
.product-box:hover{
  border:2px solid #60b725;
  box-shadow: none;
}
@media (min-width: 768px){
  .button-width{
    width: 110px !important;
  }
}
</style>
